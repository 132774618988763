.chart-container {
    border-radius: 5%;
    box-shadow: 0 0 11px rgba(33, 33, 33, .2);
    /* padding: 5px; */
    /* margin: 5px */
    margin-bottom: 11px;
}

.chart-container:hover {
    box-shadow: 0 0 50px #ccc;
}

.modal-90w {
    width: 90vw;
    /* Occupy the 90% of the screen width */
    max-width: 90vw;
}

.random-chart-flex-container-1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* flex: 200px */
}

.flex-item {
    flex: 20%
}

.flex-item-type-2 {
    flex: 50%
}

.wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(80px, auto);

    /* display: grid;
  grid-template-rows: repeat(3, 200px);
  gap: 10px;
  grid-auto-flow: column;
  grid-auto-columns: 300px 100px; */
}

.editable-box {
    margin-left: 5px;
    padding: 0px 10px 0px 10px;
    border: 1px solid #ccc;
}

.editable-box--editing {
    /* Add any styles you want for the editable state */
    margin-left: 5px;
    padding: 0px 10px 0px 10px;

    border: 1px solid blue;
}

[contenteditable] {
    outline: 0px solid transparent;
}

.custom-chart-container {
    border: 2px solid rgb(177, 177, 177);
    border-radius: 20px;
    margin: 5px;
    padding: 10px;
    background-color: rgb(251, 251, 251);
}

.line {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}

.line:before,
.line:after {
    content: "";
    flex-grow: 1;
    height: 2px;
    background-color: black;
    margin: 0 10px;
}

.line span {
    background-color: white;
    padding: 0 10px;
}

.line Button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    text-decoration: none;
}

/* .line Button:hover,
.line Button:focus {
    color: inherit;
    text-decoration: none;
} */

.indicator {
    cursor: pointer;
    padding: 10px;
    /* Adjust this to change size */
    text-align: center;
    border: 1px #666 solid;
    margin: 3px;
    border-radius: 50%;
    /* Makes the shape a circle */
}

.indicator.active {
    color: #fff;
    background: #666;
}

.vertical-line {
    width: 1px;
    height: 100%;
    background-color: #000;
    margin: 0 auto;
}

.detail-summary {
    /* font-weight: bold; */
    cursor: pointer;
}

.modal-right {
    width: 80% !important;
    top: 5%;
    height: 40%;
    left: 40%;
}

.modal-right .modal-title {
    font-size: 17px;
    /* You can adjust the size as needed */
    font-weight: normal;
}