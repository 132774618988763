.small-font-table {
    font-size: 15px;
}

.header-font-table {
    font-size: 15px;
}

.my-details {
    /* Custom styles for the details tag */
    border: 2px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0px;
}