body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blinking-background {
  /* background-color: yellow; */
  animation: blinkingBackground 1.0s;
}

@keyframes blinkingBackground {
  0% {
    background-color: limegreen;
  }

  /* 49% {
    background-color: transparent;
  }

  50% {
    background-color: transparent;
  }

  99% {
    background-color: transparent;
  } */

  100% {
    background-color: transparent;
  }
}

.btn-fill-theme {
  background-color: #6E48AA;
  border-color: white;
  color: white;
  /* border-width: 3px; */
}

.btn-fill-theme:hover {
  background-color: #410985;
  border-color: white;
  color: white;

}

.btn-fill-theme:active {
  background-color: #410985;
  border-color: white;
  color: white;
  box-shadow: 0 0 0 0.25rem rgba(100, 80, 253, 0.5) !important
}