.blinking {
    animation: blinkingText 0.8s;
}

@keyframes blinkingText {
    0% {
        color: #000;
    }

    49% {
        color: transparent;
    }

    50% {
        color: transparent;
    }

    99% {
        color: transparent;
    }

    100% {
        color: #000;
    }
}

.custom-tabs .nav-item .nav-link {
    color: #6E48AA;
    /* This can be your default color for non-active tabs */
}

.custom-tabs .nav-item .nav-link.active {
    border-bottom: 2px solid #410985 !important;
    color: #410985 !important;
}

.custom-tabs .nav-item .nav-link:hover {
    color: #410985;
    /* Change this to whatever hover color you prefer */
}

.blinking-cursor {
    display: inline-block;
    vertical-align: middle;
    opacity: 1;
    margin-left: 3px;
    animation: blink 0.7s infinite;
    position: relative;
    font-size: 50px;
    top: -8px;
    /* You can tweak this value to perfectly align the cursor */
}



@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}