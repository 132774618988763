.faq p {
    display: none;
}

.faq h3:hover~p {
    display: block;
}

pre {
    white-space: pre-wrap;
    /* css-3 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
}

.center-text-container {
    display: flex;
    align-items: center;
    /* Vertically center items */
    justify-content: center;
    /* Horizontally center items */
}